import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import Grid from "../assets/svg/grid.svg"
import { TweenMax } from "gsap/TweenMax"

//Acceptable Modifiers: primary, special

const ViewAll = ({ text, link }) => {
    return (
        <TransitionLink
            to={link}
            className="view-all"
            exit={{
                trigger: ({ exit, node }) => {
                    window.scrollTo({
                        left: 0,
                        top: 0,
                    })
                    TweenMax.to(node, 0.9, {
                        autoAlpha: 0,
                    })
                },
                length: 1,
                zIndex: 2,
            }}
            entry={{
                trigger: ({ entry, node }) => {
                    TweenMax.fromTo(
                        node,
                        0.9,
                        {
                            autoAlpha: 0,
                        },
                        { autoAlpha: 1, delay: 0.9 }
                    )
                },
                delay: 1,
                zIndex: 0,
            }}
        >
            {text}
            <Grid width={30} />
        </TransitionLink>
    )
}

export default ViewAll
