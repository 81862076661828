import React from "react"
import * as moment from "moment"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import Button from "./button"

// var moment = require('moment');

const Card = ({ image, category, date, title, excerpt, slug, tags }) => {
    const formattedDate = moment(date).format("MMMM Do YYYY")
    let firstTag = ""
    if (tags !== undefined) {
        firstTag = tags[0].name
    } else {
        firstTag = category[0]
    }

    return (
        <article className={`card ${category}`}>
            <div className="card__wrapper">
                <figure className="card__img">
                    {image && (
                        <img
                            src={image.node.sourceURL}
                            alt={image.node.altText}
                            srcSet={image.node.srcSet}
                        />
                    )}
                    <span className="card__cat">{firstTag}</span>
                </figure>
                <div className="card__content">
                    <p className="card__date small-text">{formattedDate}</p>
                    <h4 className="card__title">{title}</h4>
                    <p
                        className="card__excerpt"
                        dangerouslySetInnerHTML={{ __html: excerpt }}
                    />
                    <Button
                        modifier="primary"
                        text="read more"
                        link={`/${slug}`}
                    />
                </div>
            </div>
            <TransitionLink
                to={`/${slug}`}
                className="card__full-link"
                exit={{
                    trigger: ({ exit, node }) => {
                        window.scrollTo({
                            left: 0,
                            top: 0,
                        })
                        TweenMax.to(node, 0.9, {
                            autoAlpha: 0,
                        })
                    },
                    length: 1,
                    zIndex: 2,
                }}
                entry={{
                    trigger: ({ entry, node }) => {
                        TweenMax.fromTo(
                            node,
                            0.9,
                            {
                                autoAlpha: 0,
                            },
                            { autoAlpha: 1, delay: 0.9 }
                        )
                    },
                    delay: 1,
                    zIndex: 0,
                }}
            ></TransitionLink>
        </article>
    )
}

export default Card
