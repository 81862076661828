import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import Arrow from "../assets/svg/arrow-right.svg"

//Acceptable Modifiers: primary, special

const Button = ({ modifier, text, link }) => {
    return (
        <TransitionLink
            to={link}
            className={`btn-${modifier}`}
            exit={{
                trigger: ({ exit, node }) => {
                    window.scrollTo({
                        left: 0,
                        top: 0,
                    })
                    TweenMax.to(node, 0.9, {
                        autoAlpha: 0,
                    })
                },
                length: 1,
                zIndex: 2,
            }}
            entry={{
                trigger: ({ entry, node }) => {
                    TweenMax.fromTo(
                        node,
                        0.9,
                        {
                            autoAlpha: 0,
                        },
                        { autoAlpha: 1, delay: 0.9 }
                    )
                },
                delay: 1,
                zIndex: 0,
            }}
        >
            {text}
            {modifier === `primary` ? <Arrow width={30} /> : ""}
        </TransitionLink>
    )
}

export default Button
