import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollableSection from "./scrollable-section"
import Img from "gatsby-image"
import Button from "./button"

const HOME_INVOLVED_QUERY = graphql`
    query HomeInvolvedQuery {
        wpgraphql {
            page(id: "cGFnZToxNTU=") {
                id
                title
                getInvolved {
                    copy
                    headline
                    image {
                        srcSet
                        sourceUrl
                    }
                }
            }
        }
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const GetInvolved = ({ isHome }) => {
    // const { placeholderImage } = this.props.data
    const {
        wpgraphql: {
            page: {
                getInvolved: { copy, headline, image },
            },
        },
        placeholderImage,
    } = useStaticQuery(HOME_INVOLVED_QUERY)

    return (
        <ScrollableSection
            classNames={
                isHome ? `get-involved get-involved--home` : `get-involved`
            }
        >
            <div className="get-involved__bg">
                <Img
                    fluid={placeholderImage.childImageSharp.fluid}
                    style={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        width: `100%`,
                        height: `100%`,
                        opacity: `0.06`,
                    }}
                />
            </div>
            <div
                className={
                    isHome
                        ? `get-involved__wrapper get-involved__wrapper--home`
                        : `get-involved__wrapper`
                }
            >
                <figure
                    className={
                        isHome
                            ? `get-involved__image get-involved__image--home`
                            : `get-involved__image`
                    }
                >
                    <img
                        src={image.sourceUrl}
                        srcSet={image.srcSet}
                        alt={headline}
                    />
                </figure>
                <div
                    className={
                        isHome
                            ? `get-involved__content get-involved__content--home`
                            : `get-involved__content`
                    }
                >
                    <h2>{headline}</h2>
                    <p>{copy}</p>
                    <Button
                        modifier="special"
                        text="get involved"
                        link="/get-involved"
                    />
                </div>
            </div>
        </ScrollableSection>
    )
}

export default GetInvolved
