import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ScrollableSection from "../scrollable-section"
import ViewAll from "../view-all"
import Card from "../card"

const HOME_NEWS_QUERY = graphql`
    query HomeNewsQuery {
        wpgraphql {
            posts(first: 2) {
                nodes {
                    slug
                    title
                    featuredImage {
                        node {
                            srcSet
                            sourceUrl
                            altText
                        }
                    }
                    excerpt
                    id
                    tags {
                        nodes {
                            name
                        }
                    }
                    date
                }
            }
        }
    }
`

const HomeNews = props => {
    const {
        wpgraphql: {
            posts: { nodes: posts },
        },
    } = useStaticQuery(HOME_NEWS_QUERY)

    const modifier = props.modifier ? props.modifier : ""

    if (posts.length < 1) {
        return null
    } else {
        return (
            <ScrollableSection classNames={`home-news home-news--${modifier}`}>
                <div className="home-news__wrapper">
                    <h3>News + Events</h3>
                    <div className="home-news__card-container">
                        {posts.map(post => (
                            <Card
                                key={post.id}
                                date={post.date}
                                category={
                                    post.tags.nodes[0] !== `undefined`
                                        ? [post.tags.nodes[0].name]
                                        : [`news`]
                                }
                                image={post.featuredImage}
                                title={post.title}
                                excerpt={post.excerpt}
                                slug={post.slug}
                            />
                        ))}
                    </div>
                    <div className="home-news__all">
                        <ViewAll
                            link={`/news-events`}
                            text={`view all news + events`}
                        />
                    </div>
                    {props.bgText && (
                        <p className="vertical-text">{props.bgText}</p>
                    )}
                </div>
            </ScrollableSection>
        )
    }
}

export default HomeNews
